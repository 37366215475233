@tailwind base;
@tailwind components;
@tailwind utilities;


/* Font 1 */
@font-face {
    font-family: 'bebasneue';
    src: url('./fonts/bebasneue-regular-webfont.woff2') format('woff2'),
         url('./fonts/bebasneue-regular-webfont.woff') format('woff');
    font-weight: normal; 
    font-style: normal; 
}

/* Font 2 */
@font-face {
    font-family: 'gruppo';
    src: url('./fonts/gruppo-regular-webfont.woff2') format('woff2'),
         url('./fonts/gruppo-regular-webfont.woff') format('woff');
    font-weight: normal; 
    font-style: normal; 
}

/* Font 3 */
@font-face {
    font-family: 'lavishlyyours';
    src: url('./fonts/lavishlyyours-regular-webfont.woff2') format('woff2'),
         url('./fonts/lavishlyyours-regular-webfont.woff') format('woff');
    font-weight: normal; 
    font-style: normal; 
}

